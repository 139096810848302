import {getField, updateField} from "vuex-map-fields";
import {api} from "Api";
import _ from 'lodash'

const namespaced = {
	namespaced: true
}

const getDefaultState = () => {
	return {
		StockOrder: {
			id: null,
			blno: null,
			bundle_list_version: '1.2',
			container: null,
			currency_id: 2,
			currencyrate: 1,
			currencyrate_alt: 1,
			currencyratedate: null,
			forwardingcost: 0,
			invdate: null,
			invno: null,
			lmeasurement_id: 4,
			manual_entry: null,
			ms_id: 1,
			office_id: 1,
			purchaseorder_id: null, //new field
			smeasurement_id: 4,
			speciesgroup_id: null,
			stock_country_id: 1,
			stockdescription: null,
			stockindate: null,
			stockindatestatus_id: null,
			supplier_id: null,
			title: null,
			vmeasurement_id: 1,
			warehousecost: 0,
			wmeasurement_id: 4,
			Contract: {
				productorigin_id: 1
			},
			Supplier: {
				id: null,
				title: null,
			}
		}
	}
}

const state = {
	current: {
		stockOrderSuppliers: [],
		StockOrder: {
			id: null,
			blno: null,
			bundle_list_version: 1.2,
			container: null,
			currency_id: 2,
			currencyrate: 1,
			currencyrate_alt: 1,
			currencyratedate: null,
			forwardingcost: 0,
			invdate: null,
			invno: null,
			lmeasurement_id: 4,
			manual_entry: null,
			ms_id: 1,
			office_id: 1,
			purchaseorder_id: null, //new field
			smeasurement_id: 4,
			speciesgroup_id: null,
			stock_country_id: 1,
			stockdescription: null,
			stockindate: null,
			stockindatestatus_id: null,
			supplier_id: null,
			title: null,
			vmeasurement_id: 1,
			warehousecost: 0,
			wmeasurement_id: 4,
			Contract: {
				productorigin_id: 1
			},
			Supplier: {
				id: null,
				title: null,
			}
		},
		StockOrders: [],
		totalStockOrders: 0
	},
	update: {
		StockOrder: {
			manual_entry: null,
			office_id: 1,
			Contract: {
				productorigin_id: 1
			},
			Supplier: {
				id: null,
				title: null,
			}
		}
	},
	newStockOrderSaved: false,
	newStockOrderUnsaved: false,
	updatedStockOrderSaved: false,
	updatedStockOrderUnsaved: false,
	warehouseProductGroups: [],
}

const getters = {
	getField
}

const mutations = {
	resetCurrentState (state) {
		console.log('Resetting this Stock Order...')
		state.current.StockOrder = getDefaultState().StockOrder
		// state.current.StockOrder.stockindate = new Date().toISOString().substr(0, 10)
		state.current.StockOrder.bundle_list_version = 1.2
	},
	resetState (state) {
		state.warehouseProductGroups = []
		state.current.stockOrderSuppliers = []
	},
	setStockOrderSuppliers (state, data) {
		state.current.stockOrderSuppliers = data;
	},
	setStockOrderToBeUpdated ( state, data ) {
		state.update.StockOrder = data.StockOrder
	},
	setWarehouseProductGroups ( state, data ) {
		state.warehouseProductGroups = data
	},
	updateField
}

const actions = {
	cancelStockOrderById ({commit}, val) {
		return new Promise((resolve, reject) => {
			api
				.delete("/stockorders/" + val)
				.then(response => {
					if(response.data.status == 'success') {
						resolve('done')
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error);
				})
				.finally(() => {
					resolve('error')
				})
		})
	},
	createStockOrder ( {state, rootState} ) {
		let stockOrder = _.cloneDeep(state.current.StockOrder)
		//remove unneeded properties
		delete stockOrder.Supplier
		delete stockOrder.Contract

		return new Promise( (resolve, reject) => {
			api
				.post('/stockorders', {
					stockorder: stockOrder,
					stocks: rootState.appic.stock.current.Bundles
				})
				.then((response) => {
					if(response.data.status === 'success') {
						resolve(response.data)
					} else {
						resolve('error')
					}
				})
				.catch(error => {
					reject(error)
				})
				.finally(()=>{
					resolve('error')
				})
		})
	},
	getAllStockOrders ( {commit}, payload ) {
		let filterOptions = payload.filterOptions
		let conditions = []
		if(filterOptions.month && filterOptions.year){
			let endDate = new Date(filterOptions.year, filterOptions.month,0)
			let obj = {
				field: 'stockorders.stockindate',
				value: {
					from: [filterOptions.year.toString(),filterOptions.month.toString().padStart(2,'0'),'01'].join('-'),
					to: [endDate.getFullYear(),(endDate.getMonth() + 1).toString().padStart(2,'0'), endDate.getDate().toString().padStart(2,'0')].join('-')
				}
			}
			conditions.push(obj)
		} else if(filterOptions.month == null && filterOptions.year){
			let obj = {
				field: 'stockorders.stockindate',
				value: {
					from: [filterOptions.year.toString(),'01','01'].join('-'),
					to: [filterOptions.year.toString(),'12','31'].join('-')
				}
			}
			conditions.push(obj)
		}
		if (filterOptions.office) {
			let obj = {
				field: 'stockorders.office_id',
				value: filterOptions.office
			}
			conditions.push(obj)
		}
		if (filterOptions.supplier) {
			let obj = {
				field: 'contracts.supplier_id',
				value: filterOptions.supplier
			}
			conditions.push(obj)
		}
		return new Promise((resolve, reject) => {
			api
				.get("/stockorders",{
					params: {
						conditions: conditions,
						order: [
							{
								field: 'stockorders.stockindate',
								direction: 'DESC'
							},
						]
					}
				})
				.then(response => {
					let tableData = {
						rows: response.data.data,
						totalRows: response.data.totalRows
					}
					resolve(tableData)
				})
				.catch(error => {
					reject(error);
				});
		})
	},
	getAllStockOrderSuppliers ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get('/stockorders/suppliers')
				.then(response=>{
					commit('setStockOrderSuppliers',response.data.data)
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getStockOrderToUpdateById ( {commit}, stockOrderId ) {
		return new Promise((resolve, reject) => {
			api
				.get('/stockorders/' + stockOrderId)
				.then(response => {
					commit('setStockOrderToBeUpdated', response.data.data[0]);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	getWarehouseProductGroups ({commit}) {
		return new Promise((resolve, reject) => {
			api
				.get('/stockorders/product-groups')
				.then(response => {
					commit('setWarehouseProductGroups', response.data.data);
					resolve('done')
				})
				.catch(error => {
					reject(error);
				})
				.finally(()=>{
					resolve('done')
				})
		})
	},
	resetCurrentStockOrder ( {commit} ) {
		return new Promise ((resolve, reject) => {
			commit('resetCurrentState')
			resolve('done')
		})
	},
	updateStockOrder ( {state, rootState} ) {
		let stockOrder = _.cloneDeep(state.update.StockOrder)
		//remove unneeded properties
		delete stockOrder.Supplier
		delete stockOrder.Office
		delete stockOrder.Contract

		return new Promise((resolve, reject) => {
			api
				.put('/stockorders/' + state.update.StockOrder.id, {
					stockorder: stockOrder,
					stocks: rootState.appic.stock.update.Bundles
				})
				.then((response) => {
					this.updatedStockOrderSaved = true
					this.updatedStockOrderUnsaved = false
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	}
}

export const stockorder = {
	namespaced,
	state,
	getters,
	actions,
	mutations
}