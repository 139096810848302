import {getField, updateField} from "vuex-map-fields";
import {api} from "../../../api";
import { offices } from "../../../store/modules/appic/constants";
import _ from 'lodash';

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        Invoice: {
            id: null,
            agent_commission_calc: {
                setting_id: null,
                unit_price: null,
                quantity: 0
            },
            contract_id: null,
            currency_id: 2,
            currencyrate: 1,
            footer_notes: [1,2,3,4],
            gstpercent: 0,
            gstrmrate: 0,
            has_agent_commission: null,
            headertaxschedule: null,
            hide_conversion: null,
            hidestamp: 0,
            invdate: new Date().toISOString().substr(0, 10),
            invno: '',
            is_posted: null,
            json: {
                show_sign_box: 0
            },
            longform: 0,
            posted: null,
            posted_by: null,
            remark: '',
            tmp_currencyrate: 1,
            tmp_currency_id: 1,
            tmp_invdate: null,
            type: 'ds',
            usenotifyaddress: 0,
            Agent: {
                id: null,
                has_commission: null,
                otsname: null
            },
            Bank: {
                title: null,
                swiftcode: null,
                account: null,
                beneficiary: null
            },
            Company: {
                id: null,
                title: '',
                GPCustomerNumber: ''
            },
            Contract: {
                id: null,
                bank_id: null,
                customer_id: null,
                currency_id: 2,
                destinatione_id: 1,
                fsc: 0,
                incoterm_id: null,
                incotermport: 0,
                loadingport_id: null,
                office_id: 1,
                partial_no: null,
                paymentstatement_id: null,
                revision_no: null,
                salestype_id: 1,
                shippingport_id: null,
                title: '',
                version: null
            },
            Customer: {
                id: null,
                title: '',
                company_id: ''
            },
            Deposit: {
                amount: 0,
                amount_converted: 0,
                exchange_rate: 0
            },
            Deliveryaddress: {
                id: null,
                address: ''
            },
            Localsalesorder: {
                id: null,
                bank_id: null,
                customer_id: null,
                currency_id: 2,
                office_id: 1,
                paymentstatement_id: null,
                title: ''
            },
            Manual: {
                id: null,
                bank_id: null,
                customer_id: null,
                currency_id: 2,
                office_id: 1,
                paymentstatement_id: null,
                title: ''
            },
            Office: {
                id: null,
                title: '',
            },
            otheraddress: '',
            Payment: {
                id: null,
                fully_paid: null,
                fully_paid_on: null
            },
            Paymentstatement: {
                id: null,
                title: ''
            },
            Paymentterm: {
                id: null,
                title: ''
            },
            Paymentstatus: {
                id: null,
                title: ''
            },
            Salescontact: {
                id: null,
                name: ''
            }
        },
    }
}

const state = {
    allActiveInvoices: [],
    //current data. can either be a loaded existing contract or a new unsaved contract
    current: {
        Invoice: {
            id: null,
            agent_commission_calc: {
                setting_id: null,
                unit_price: null,
                quantity: 0
            },
            contract_id: null,
            currency_id: 2,
            currencyrate: 1,
            footer_notes: [1,2,3,4],
            gstpercent: 0,
            gstrmrate: 0,
            has_agent_commission: null,
            headertaxschedule: null,
            hide_conversion: null,
            hidestamp: 0,
            invdate: new Date().toISOString().substr(0, 10),
            invno: '',
            is_posted: null,
            json: {
                show_sign_box: 0
            },
            longform: 0,
            otheraddress: '',
            posted: null,
            posted_by: null,
            remark: '',
            tmp_currencyrate: 1,
            tmp_currency_id: 1,
            tmp_invdate: null,
            type: 'ds',
            usenotifyaddress: 0,
            Agent: {
                id: null,
                has_commission: null,
                otsname: null
            },
            Bank: {
                title: null,
                swiftcode: null,
                account: null,
                beneficiary: null
            },
            Company: {
                id: null,
                title: '',
                GPCustomerNumber: ''
            },
            Contract: {
                id: null,
                bank_id: null,
                customer_id: null,
                currency_id: 2,
                destinatione_id: 1,
                fsc: 0,
                incoterm_id: null,
                incotermport: 0,
                loadingport_id: null,
                office_id: 1,
                partial_no: null,
                paymentstatement_id: null,
                revision_no: null,
                salestype_id: 1,
                shippingport_id: null,
                title: '',
                version: null
            },
            Customer: {
                id: null,
                title: '',
                company_id: ''
            },
            Deliveryaddress: {
                id: null,
                address: ''
            },
            Deposit: {
                amount: 0,
                amount_converted: 0,
                exchange_rate: 0
            },
            Localsalesorder: {
                id: null,
                bank_id: null,
                customer_id: null,
                currency_id: 2,
                office_id: 1,
                paymentstatement_id: null,
                title: ''
            },
            Manual: {
                id: null,
                bank_id: null,
                customer_id: null,
                currency_id: 2,
                office_id: 1,
                paymentstatement_id: null,
                title: ''
            },
            Office: {
                id: null,
                title: '',
            },
            Payment: {
                id: null,
                fully_paid: null,
                fully_paid_on: null,
            },
            Paymentstatement: {
                id: null,
                title: ''
            },
            Paymentterm: {
                id: null,
                title: ''
            },
            Paymentstatus: {
                id: null,
                title: ''
            },
            Salescontact: {
                id: null,
                name: ''
            }
        },
        Invoices: [],
        invoiceBuyers: [],
        totalAmounts: [],
        totalInvoices: 0,
    },
    update: {
        Invoice: {
            agent_commission_calc: {
                setting_id: null,
                unit_price: null,
                quantity: 0
            },
            has_agent_commission: null,
            Agent: {
                id: null,
                has_commission: null,
                otsname: null
            },
            json: {
                show_sign_box: 0
            },
            Bank: {
                title: null,
                swiftcode: null,
                account: null,
                beneficiary: null
            },
            Contract: {
                destinatione_id: 1,
                office_id: null,
                fsc: 0,
            },
            Company: {
                GPCustomerNumber: null
            },
            Deposit: {},
            Payment: {},
            Paymentstatement: {},
            Paymentterm: {},
            Paymentstatus: {},
            Salescontact: {}
        }
    },
    openForexView: false,
    newInvoiceSaved: false,
    newInvoiceUnsaved: false,
    updatedInvoiceUnsaved: false,
    updatedInvoiceSaved: false
}

const getters = {
    allActiveInvoices: state => {
        return state.allActiveInvoices
    },
    getField,
    invoiceBuyers: state => { return state.current.invoiceBuyers },
    openForexView: state => { return state.openForexView },
}

const mutations = {
    resetCurrentState(state) {
        console.log('Resetting this invoice...')
        state.current.Invoice = getDefaultState().Invoice
        state.newInvoiceUnsaved = false
        state.newInvoiceSaved = false
    },
    resetState (state){
        console.log('Resetting invoices...')
        state.allActiveInvoices = []
    },
    setAllActiveInvoices (state, data) {
        state.allActiveInvoices = data
    },
    setCurrentInvoice (state, payload){
        let contract = payload
        state.current.Invoice.Contract.id = contract.Contract.id
        state.current.Invoice.Contract.bank_id = contract.Bank.id
        state.current.Invoice.Contract.currency_id = contract.Contract.currency_id
        state.current.Invoice.Contract.customer_id = contract.Customer.id
        state.current.Invoice.Contract.fsc = contract.Contract.fsc
        state.current.Invoice.Contract.incoterm_id = contract.Contract.incoterm_id
        state.current.Invoice.Contract.incotermport = contract.Contract.incotermport
        state.current.Invoice.Contract.loadingport_id = contract.Contract.loadingport_id
        state.current.Invoice.Contract.office_id = contract.Office.id
        state.current.Invoice.Contract.salestype_id = contract.Contract.salestype_id
        state.current.Invoice.Contract.shippingport_id = contract.Contract.shippingport_id
        state.current.Invoice.Contract.title = contract.Contract.title
        state.current.Invoice.Contract.version = contract.Contract.version
        state.current.Invoice.Contract.partial_no = contract.Contract.partial_no
        state.current.Invoice.Contract.revision_no = contract.Contract.revision_no
        if (contract.Contract.salestype_id == 5) {
            state.current.Invoice.currency_id = offices.find(office => office.Office.id === contract.Office.id)?.Office?.currency_id;
        } else {
            state.current.Invoice.currency_id = contract.Contract.currency_id
        }
        state.current.Invoice.contract_id = contract.Contract.id
        state.current.Invoice.Office.title = contract.Office.title
        state.current.Invoice.Customer.title = contract.Customer.title
        state.current.Invoice.Company.GPCustomerNumber = contract.Customercompany.GPCustomerNumber
        state.current.Invoice.Salescontact.name = contract.Salescontact.name
        state.current.Invoice.Paymentstatement.id = contract.Paymentstatement.id
        state.current.Invoice.Paymentstatement.title = contract.Paymentstatement.title
        state.current.Invoice.Paymentterm.id = contract.Paymentstatement.id
        state.current.Invoice.Paymentterm.title = contract.Paymentstatement.title
        state.current.Invoice.headertaxschedule = offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxcode
        state.current.Invoice.gstpercent = offices.find(office => office.Office.id === contract.Office.id)?.Office?.taxrate
    },
    setInvoices (state,data) {
        state.current.Invoices = data;
    },
    setInvoiceToBeUpdated (state, data) {
        state.update.Invoice = data.Invoice
    },
    setInvoiceBuyers (state,data) {
        state.current.invoiceBuyers = data;
    },
    setOpenForexView (state,data) {
        state.openForexView = data
    },
    setTotalAmounts (state,val) {
        state.current.totalAmounts = val
    },
    setTotalInvoices (state,val) {
        state.current.totalInvoices = val
    },
    updateField
}

const actions = {
    cancelInvoiceById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/invoices/" + val)
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
                // .finally(() => {
                //     resolve('error')
                // })
        })
    },
    cancelInvoice ({commit}) {
        commit('cancelInvoice')
    },
    createInvoice ({state, rootState}) {
        let invoice = _.cloneDeep(state.current.Invoice)
        let contract = _.cloneDeep(state.current.Invoice.Contract)
        //remove unneeded properties
        delete invoice.documents
        delete invoice.Bank
        delete invoice.Company
        delete invoice.Contract
        delete invoice.Customer
        delete invoice.Deliveryaddress
        delete invoice.Deposit
        delete invoice.Localsalesorder
        delete invoice.Manual
        delete invoice.Office
        delete invoice.Payment
        delete invoice.Paymentstatement
        delete invoice.Paymentterm
        delete invoice.Paymentstatus
        delete invoice.Salescontact

        return new Promise((resolve, reject) => {
            api
                .post("/invoices" , {
                        contract: contract,
                        invoice: invoice,
                        invoiceitems: rootState.appic.invoicesalesitem.current.Salesitems,
                    }
                )
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve(response.data)
                    // } else {
                    //     resolve(response.data.message)
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllActiveInvoices ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/invoices/active")
                .then(response => {
                    commit('setAllActiveInvoices',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllInvoices ( {commit}, payload ) {
        // let filterOptions = payload.filterOptions
        // let conditions = []
        // if(filterOptions.year){
        //     let obj = {
        //         field: 'invoices.year',
        //         value: filterOptions.year
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.month){
        //     let obj = {
        //         field: 'invoices.month',
        //         value: filterOptions.month
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.buyer?.Customer?.id){
        //     let obj = {
        //         field: 'contracts.customer_id',
        //         value: filterOptions.buyer.Customer.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.marketing?.Salesperson?.id){
        //     let obj = {
        //         field: 'contracts.salescontact_id',
        //         value: filterOptions.marketing.Salesperson.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.office?.Office?.id){
        //     let obj = {
        //         field: 'offices.id',
        //         value: filterOptions.office.Office.id
        //     }
        //     conditions.push(obj)
        // }
        return new Promise((resolve, reject) => {
            if(payload.tableOptions.page != null) {
                api
                    .get("/invoices", {
                        params: {
                            // conditions: conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'Invoice.invdate',
                                    direction: 'DESC'
                                },
                            ]
                        }
                    })
                    .then(response => {
                        commit('setInvoices', response.data.data)
                        commit('setTotalInvoices', response.data.totalRows)
                        commit('setTotalAmounts', response.data.totalAmounts)
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve('done')
            }
        })
    },
    getAllInvoiceBuyers ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/invoices/buyers",{
                })
                .then(response => {
                    commit('setInvoiceBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getInvoiceToUpdateById ({commit},invoice_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/invoices/" + invoice_id,{
                })
                .then(response => {
                    commit('setInvoiceToBeUpdated',response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    loadUninvoicedContract({commit},payload){
        return new Promise((resolve, reject) => {
            commit('setCurrentInvoice', payload)
            resolve('done')
        })
    },
    resetAllActiveInvoices ({commit}){
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCurrentInvoice ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    resetInvoice ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    searchAllInvoices ({commit},payload) {
        // let conditions = []
        // let obj = {
        //     field: payload.search.field,
        //     value: payload.search.value
        // }
        // conditions.push(obj)
        return new Promise((resolve, reject) => {
            api
                .get("/invoices",{
                    params: {
                        conditions: payload.conditions,
                        pagination: {
                            skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                            take: payload.tableOptions.itemsPerPage
                            // skip: (payload.tableOptions.page - 1) * -1,
                            // take: -1
                        },
                        order: payload.order
                        // order: [
                        //     {
                        //         field: 'invoices.invdate',
                        //         direction: 'DESC'
                        //     },
                        // ]
                    }
                })
                .then(response => {
                    commit('setInvoices', response.data.data)
                    commit('setTotalInvoices', response.data.totalRows)
                    commit('setTotalAmounts', response.data.totalAmounts)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
    updateAgentCommission( {state} ) {
        const invoice = {
            agent_commission_calc: _.cloneDeep(state.update.Invoice.agent_commission_calc),
            agent_commission_amount: _.cloneDeep(state.update.Invoice.agent_commission_amount),
            agent_commission_paid: _.cloneDeep(state.update.Invoice.agent_commission_paid),
            has_agent_commission: _.cloneDeep(state.update.Invoice.has_agent_commission),
            net_amount: _.cloneDeep(state.update.Invoice.net_amount),
            invno: _.cloneDeep(state.update.Invoice.invno),
        }

        const contract = {
            id: _.cloneDeep(state.update.Invoice.Contract.id),
        }

        return new Promise((resolve, reject) => {
            api
                .put('/invoices/' + state.update.Invoice.id,{
                    contract: contract,
                    invoice: invoice,
                    invoiceitems: []
                })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateInvoice ({state, rootState}) {
        let invoice = _.cloneDeep(state.update.Invoice)
        let contract = _.cloneDeep(state.update.Invoice.Contract)
        //remove unneeded properties
        delete invoice.documents
        delete invoice.Bank
        delete invoice.Company
        delete invoice.Contact
        delete invoice.Contract
        delete invoice.Currency
        delete invoice.Customer
        delete invoice.Deliveryaddress
        delete invoice.Deposit
        delete invoice.Loadingport
        delete invoice.Localsalesorder
        delete invoice.Manual
        delete invoice.Office
        delete invoice.Payment
        delete invoice.Paymentterm
        delete invoice.Paymentstatement
        delete invoice.Paymentstatus
        delete invoice.Shippingport
        delete invoice.Salescontact
        delete invoice.Total

        return new Promise((resolve, reject) => {
            api
                .put('/invoices/' + state.update.Invoice.id, {
                    contract: contract,
                    invoice: invoice,
                    invoiceitems: rootState.appic.invoicesalesitem.update.Salesitems,
                })
                .then(response => {
                    this.updatedInvoiceSaved = true
                    this.updatedInvoiceUnsaved = false
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    // resolve(error)
                })
                // .then(()=>{
                //     resolve('done')
                // });
        })
    },
    updateTemporaryInvoiceFields ({state}) {
        const invoice = {
            tmp_currency_id: state.update.Invoice.tmp_currency_id,
            tmp_currencyrate: state.update.Invoice.tmp_currencyrate,
            tmp_invdate: state.update.Invoice.tmp_invdate,
        }
        return new Promise((resolve, reject) => {
            api
                .put('/invoices/' + state.update.Invoice.id, {
                    invoice: invoice,
                    contract: {
                        id: state.update.Invoice.Contract.id
                    },
                    invoiceitems: []
                })
                .then(response => {
                    this.updatedInvoiceSaved = true
                    this.updatedInvoiceUnsaved = false
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    // resolve(error)
                })
                // .then(()=>{
                //     resolve('done')
                // });
        })
    }
}

export const invoice = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}