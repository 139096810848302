/**
 * App Config File
 */
export default {
	appLogo: '/static/img/appic/site-logo.png',                                   	// App Logo,
	appLogoOnly: '/static/img/appic/site-logo-only.gif',							// APP logo only, no text
	darkLogo: '/static/img/appic/site-dark-logo.png',							    // dark logo
	appLogo2: '/static/img/session.png',
    appSessionLogo: '/static/img/appic/login_logo.png',        						// App Logo 2 For Login & Signup Page
	brand: 'APP Timber',                                        			        // Brand Name
	copyrightText: 'APP Timber © 2019 All Rights Reserved.',                     	// Copyright Text
	enableUserTour: false,   		// Enable User Tour
	weatherApiId: '',								// weather API Id
	weatherApiKey: ''								// weather APi key
}
