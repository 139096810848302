import {getField, updateField} from "vuex-map-fields";
import {api} from "Api";

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        DebitNote: {
            id: null,
            agent_commission_calc: {
                setting_id: null,
                unit_price: null,
                quantity: 0
            },
            agent_commission_amount: 0,
            agent_commission_paid: null,
            amount: 0,
            cfrciffee: 0,
            comm_from_customer: 0,
            contact_id: null,
            containercount: 0,
            containersize_id: 1,
            contract_id: null,
            currency_id: 2,
            dndate: null,
            dnvalue: 0,
            etddate: null,
            fobfee: 0,
            fasvalue: 0,
            freight: 0,
            gstpercent: 0,
            gstrmrate: 0,
            has_agent_commission: null,
            headertaxschedule: null,
            invno: null,
            is_posted: null,
            mbffee: 0,
            mbfqty: 0,
            net_amount: 0,
            office_id: 1,
            payment_date: null,
            posted: null,
            posted_by: null,
            title: null,
            valuetype: 0,
            Agent: {
                id: null,
                has_commission: null,
                otsname: null
            },
            Company: {
                id: null,
                title: '',
                GPCustomerNumber: ''
            },
            Contract: {
                id: null,
                bank_id: null,
                commission_term_against_id: null,
                commission_term_id: null,
                commission_unit_price: 0,
                customer_id: null,
                currency_id: 2,
                fsc: 0,
                incoterm_id: null,
                incotermport: 0,
                loadingport_id: null,
                margin: 0,
                office_id: 1,
                partial_no: null,
                paymentstatement_id: null,
                revision_no: null,
                shippingport_id: null,
                title: '',
                version: null,
            },
            Customer: {
                id: null,
                title: null,
                company_id: null
            },
            Office: {
                id: 1,
                title: '',
            },
            Salescontact: {
                id: null,
                title: ''
            },
            Supplier: {
                id: null,
                title: null,
                company_id: null
            }
        }
    }
}

const state = {
    //current data. can either be a loaded existing contract or a new unsaved contract
    allActiveDebitNotes: [],
    current: {
        DebitNote: {
            id: null,
            agent_commission_calc: {
                setting_id: null,
                unit_price: null,
                quantity: 0
            },
            agent_commission_amount: 0,
            agent_commission_paid: null,
            amount: 0,
            cfrciffee: 0,
            comm_from_customer: 0,
            contact_id: null,
            containercount: 0,
            containersize_id: 1,
            contract_id: null,
            currency_id: 2,
            dndate: null,
            dnvalue: 0,
            etddate: null,
            fasvalue: 0,
            fobfee: 0,
            freight: 0,
            gstrmrate: 0,
            gstpercent: 0,
            has_agent_commission: null,
            headertaxschedule: null,
            invno: null,
            is_posted: null,
            mbffee: 0,
            mbfqty: 0,
            net_amount: 0,
            office_id: 1,
            payment_date: null,
            posted: null,
            posted_by: null,
            title: null,
            valuetype: 0,
            Agent: {
                id: null,
                has_commission: null,
                otsname: null
            },
            Company: {
                id: null,
                title: '',
                GPCustomerNumber: ''
            },
            Contract: {
                id: null,
                bank_id: null,
                commission_term_against_id: null,
                commission_term_id: null,
                commission_unit_price: 0,
                customer_id: null,
                currency_id: 2,
                fsc: 0,
                incoterm_id: null,
                incotermport: 0,
                loadingport_id: null,
                margin: 0,
                office_id: 1,
                partial_no: null,
                paymentstatement_id: null,
                revision_no: null,
                shippingport_id: null,
                title: '',
                version: null,
            },
            Customer: {
                id: null,
                title: null,
                company_id: null
            },
            Office: {
                id: 1,
                title: '',
            },
            Salescontact: {
                id: null,
                title: ''
            },
            Supplier: {
                id: null,
                title: null,
                company_id: null
            }
        },
        DebitNotes: [],
        buyerAndSupplierContacts: [],
        debitNoteBuyers: [],
        debitNoteSuppliers: [],
        totalAmounts: [],
        totalValues: [],
        totalDebitNotes: 0,
    },
    update: {
        buyerAndSupplierContacts: [],
        DebitNote: {
            agent_commission_calc: {
                setting_id: null,
                unit_price: null,
                quantity: 0
            },
            Agent: {
                id: null,
                has_commission: null,
                otsname: null
            },
            Company: {
                GPCustomerNumber: ''
            },
            Contract: {},
            Customer: {},
            Office: {},
            Salescontact: {},
            Supplier: {}
        }
    },
    openForexView: false,
    newDebitNoteSaved: false,
    newDebitNoteUnsaved: false,
    updatedDebitNoteSaved: false,
    updatedDebitNoteUnsaved: false,
}

const getters = {
    debitNoteCreditors: state => { return [] },
    getField,
    openForexView: state => { return state.openForexView },
}

const mutations = {
    calculateCommission (state, prefix) {
        let marginPct = 0;
        switch(state[prefix].DebitNote.Contract.commission_term_id){
            case 0:
                marginPct = parseFloat(state[prefix].DebitNote.cfrciffee)/100
                state[prefix].DebitNote.amount = parseFloat(state[prefix].DebitNote.dnvalue) * marginPct
                break
            case 1:
                var freight = parseFloat(state[prefix].DebitNote.freight * state[prefix].DebitNote.containercount)
                marginPct = parseFloat(state[prefix].DebitNote.fobfee)/100
                state[prefix].DebitNote.amount = (parseFloat(state[prefix].DebitNote.dnvalue) + freight) * marginPct
                break
            case 2:
                state[prefix].DebitNote.amount = parseFloat(state[prefix].DebitNote.fasvalue) * marginPct
                break
            case 3:
                state[prefix].DebitNote.amount = parseFloat(state[prefix].DebitNote.mbfqty) * parseFloat(state[prefix].DebitNote.mbffee)
                break
        }
    },
    resetCurrentState ( state ) {
        console.log('Resetting this debit note...')
        state.current.DebitNote = getDefaultState().DebitNote
    },
    resetState (state){
        state.current.DebitNotes = []
        state.current.buyerAndSupplierContacts = []
        state.current.debitNoteBuyers = []
        state.current.debitNoteSuppliers = []
    },
    setAllActiveDebitNotes ( state, data ) {
        state.allActiveDebitNotes = data
    },
    setDebitNotes ( state, data ) {
        state.current.DebitNotes = data;
    },
    setDebitNoteBuyers ( state,data ) {
        state.current.debitNoteBuyers = data;
    },
    setDebitNoteSuppliers ( state,data ) {
        state.current.debitNoteSuppliers = data;
    },
    setDebitNoteToBeUpdated ( state, data ) {
        state.update.DebitNote = data.DebitNote
    },
    setTotalDebitNotesAmounts ( state, val ) {
        state.current.totalAmounts = val
    },
    setTotalDebitNotesValues ( state, val ) {
        state.current.totalValues = val
    },
    setTotalDebitNotes ( state, val ) {
        state.current.totalDebitNotes = val
    },
    updateField,
}

const actions = {
    calculateCommission ( {commit}, prefix ) {
        commit('calculateCommission', prefix)
    },
    cancelDebitNoteById ({commit}, val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/debitnotes/" + val)
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                })
                // .finally(() => {
                //     resolve('error')
                // })
        })
    },
    createDebitNote ({state, rootState}) {
        let debitNote = _.cloneDeep(state.current.DebitNote)
        //remove unneeded properties
        delete debitNote.Agent
        delete debitNote.Company
        delete debitNote.Customer
        delete debitNote.Office
        delete debitNote.Salescontact
        delete debitNote.Supplier

        return new Promise((resolve, reject) => {
            api
                .post("/debitnotes" , {
                        debitnote: debitNote
                    }
                )
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve(response.data)
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllActiveDebitNotes ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/debitnotes/active")
                .then(response => {
                    commit('setAllActiveDebitNotes',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllDebitNotes ( {commit}, payload ) {
        // let filterOptions = payload.filterOptions
        // let conditions = []
        // if(filterOptions.year){
        //     let obj = {
        //         field: 'debitnotes.year',
        //         value: filterOptions.year
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.month){
        //     let obj = {
        //         field: 'debitnotes.month',
        //         value: filterOptions.month
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.buyer?.Customer?.id){
        //     let obj = {
        //         field: 'debitnotes.customer_id',
        //         value: filterOptions.buyer.Customer.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.marketing?.Salesperson?.id){
        //     let obj = {
        //         field: 'debitnotes.salescontact_id',
        //         value: filterOptions.marketing.Salesperson.id
        //     }
        //     conditions.push(obj)
        // }
        // if(filterOptions.office?.Office?.id){
        //     let obj = {
        //         field: 'offices.id',
        //         value: filterOptions.office.Office.id
        //     }
        //     conditions.push(obj)
        // }
        return new Promise((resolve, reject) => {
            if(payload.tableOptions.page != null) {
                api
                    .get("/debitnotes", {
                        params: {
                            // conditions: conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'debitnotes.dndate',
                                    direction: 'DESC'
                                },
                                {
                                    field: 'debitnotes.title',
                                    direction: 'ASC'
                                }
                            ]
                        }
                    })
                    .then(response => {
                        commit('setDebitNotes', response.data.data)
                        commit('setTotalDebitNotes', response.data.totalRows)
                        commit('setTotalDebitNotesAmounts', response.data.totalAmounts)
                        commit('setTotalDebitNotesValues', response.data.totalValues)
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve('done')
            }
        })
    },
    getAllDebitNoteBuyers ( {commit} ) {
        return new Promise((resolve, reject) => {
            api
                .get("/debitnotes/buyers",{
                })
                .then(response => {
                    commit('setDebitNoteBuyers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllDebitNoteSuppliers ( {commit} ) {
        return new Promise((resolve, reject) => {
            api
                .get("/debitnotes/suppliers",{
                })
                .then(response => {
                    commit('setDebitNoteSuppliers',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getDebitNoteToUpdateById ( {commit}, debitnote_id ){
        return new Promise((resolve, reject) => {
            api
                .get('/debitnotes/' + debitnote_id)
                .then(response => {
                    commit('setDebitNoteToBeUpdated', response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    resetAllActiveDebitNotes ( {commit} ) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCurrentDebitNote ( {commit} ) {
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    searchAllDebitNotes ({commit}, payload) {
        // let conditions = []
        // let obj = {
        //     field: payload.search.field,
        //     value: payload.search.value
        // }
        // conditions.push(obj)
        return new Promise((resolve, reject) => {
            api
                .get("/debitnotes",{
                    params: {
                        conditions: payload.conditions,
                        pagination: {
                            skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                            take: payload.tableOptions.itemsPerPage
                            // skip: (payload.tableOptions.page - 1) * -1,
                            // take: -1
                        },
                        order: payload.order
                        // order: [
                        //     {
                        //         field: 'debitnotes.dndate',
                        //         direction: 'DESC'
                        //     },
                        //     {
                        //         field: 'debitnotes.title',
                        //         direction: 'ASC'
                        //     }
                        // ]
                    }
                })
                .then(response => {
                    commit('setDebitNotes', response.data.data)
                    commit('setTotalDebitNotes', response.data.totalRows)
                    commit('setTotalDebitNotesAmounts', response.data.totalAmounts)
                    commit('setTotalDebitNotesValues', response.data.totalValues)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
    updateAgentCommission( {state} ) {
        const debitNote = {
            agent_commission_calc: _.cloneDeep(state.update.DebitNote.agent_commission_calc),
            agent_commission_amount: _.cloneDeep(state.update.DebitNote.agent_commission_amount),
            agent_commission_paid: _.cloneDeep(state.update.DebitNote.agent_commission_paid),
            has_agent_commission: _.cloneDeep(state.update.DebitNote.has_agent_commission),
            net_amount: _.cloneDeep(state.update.DebitNote.net_amount),
            title: _.cloneDeep(state.update.DebitNote.title),
        }

        return new Promise((resolve, reject) => {
            api
                .put('/debitnotes/' + state.update.DebitNote.id,{
                        contract: {},
                        debitnote: debitNote
                    }
                )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updatePaymentDate( {state} ) {
        const debitNote = {
            payment_date: _.cloneDeep(state.update.DebitNote.payment_date),
            title: _.cloneDeep(state.update.DebitNote.title),
        }

        return new Promise((resolve, reject) => {
            api
                .put('/debitnotes/' + state.update.DebitNote.id,{
                        contract: {},
                        debitnote: debitNote
                    }
                )
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    updateDebitNote ( {state} ) {
        let debitNote = _.cloneDeep(state.update.DebitNote)
        let contract = _.cloneDeep(state.update.DebitNote.Contract)

        //remove unneeded properties
        delete debitNote.Agent
        delete debitNote.Bank
        delete debitNote.Company
        delete debitNote.Contract
        delete debitNote.Currency
        delete debitNote.Customer
        delete debitNote.Office
        delete debitNote.Payment
        delete debitNote.Paymentstatement
        delete debitNote.Paymentterm
        delete debitNote.Salescontact
        delete debitNote.Supplier
        delete debitNote.Total

        return new Promise((resolve, reject) => {
            api
                .put('/debitnotes/' + state.update.DebitNote.id,{
                        contract: contract,
                        debitnote: debitNote
                    }
                )
                .then(response => {
                    this.updatedCommissionInvoiceSaved = true
                    this.updatedCommissionInvoiceUnsaved = false
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    }
}

export const debitnote = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}