import {getField, updateField} from "vuex-map-fields";
import { api } from "../../../api";
import _ from 'lodash';

const namespaced = {
    namespaced: true
}

const getDefaultState = () => {
    return {
        Container: {
            index: 0,
            Container: {
                id: null,
                sealno: null,
                title: null
            }
        },
        Pldo: {
            id: null,
            contract_id: null,
            grossweight: 0,
            gwmeasurement_id: 53,
            longform: null,
            netweight: 0,
            nwmeasurement_id: 53,
            pl_title: 0,
            pldodate: new Date().toISOString().substr(0, 10),
            title: null,
            Containers: [],
            ContainersToDelete: [],
            Contract: {
                id: null,
                containercount: null,
                containersize_id: null,
                customer_id: null,
                fsc: null,
                partial_no: null,
                supplier_id: null,
                revision_no: null,
                title: '',
                version: null
            },
            Customer: {
                id: null,
                title: '',
                company_id: ''
            },
            Invoice: {
                id: null,
                invno: null
            },
            Office: {
                id: null,
                title: '',
            },
            Salesdescriptions: [],
            Shipment: {
                etadate: null,
                etddate: null,
                vessel: null,
            },
            Supplier: {
                id: null,
                title: '',
            }
        }
    }
}

const state = {
    allActivePackingLists: [],
    //current data. can either be a loaded existing contract or a new unsaved contract
    current: {
        Pldo: {
            id: null,
            contract_id: null,
            grossweight: 0,
            gwmeasurement_id: 53,
            longform: null,
            netweight: 0,
            nwmeasurement_id: 53,
            pl_title: 0,
            pldodate: new Date().toISOString().substr(0, 10),
            title: null,
            Containers: [
                {
                    index: 0,
                    Container: {
                        id: null,
                        sealno: null,
                        title: null
                    }
                }
            ],
            ContainersToDelete: [],
            Contract: {
                id: null,
                containercount: null,
                containersize_id: null,
                customer_id: null,
                fsc: null,
                supplier_id: null,
                title: '',
                version: null
            },
            Customer: {
                id: null,
                title: '',
                company_id: ''
            },
            Invoice: {
                id: null,
                invno: null
            },
            Office: {
                id: null,
                title: '',
            },
            Salesdescriptions: [],
            Shipment: {
                etadate: null,
                etddate: null,
                vessel: null,
            },
            Supplier: {
                id: null,
                title: '',
            }
        },
        PackingLists: [],
        packingListBuyers: [],
        totalPackingLists: 0,
    },
    update: {
        Pldo: {
            Containers: [],
            ContainersToDelete: [],
            Contract: {},
            Customer: {},
            Invoice: {},
            Office: {},
            Salesdescriptions: [],
            Shipment: {},
            Supplier: {},
        }
    },
    newPackingListSaved: false,
    newPackingListUnsaved: false,
    updatedPackingListSaved: false,
    updatedPackingListUnsaved: false
}

const getters = {
    allActivePackingLists: state => { return state.allActivePackingLists },
    getField,
    // Containers: state => { return state.current.Containers },
    // Salesdescriptions: state => { return state.current.Salesdescriptions }
}

const mutations = {
    addContainer (state, prefix) {
        let i = 0;
        state[prefix].Pldo.Containers.push(getDefaultState().Container)
        state[prefix].Pldo.Containers.forEach((item) => {
            item.index = i
            i++
        })
    },
    deleteContainer (state, payload) {
        //add container id to ContainersToDelete
        let prefix = payload.prefix
        let index = payload.index

        let container = state[prefix].Pldo.Containers[index]
        if(container?.Container?.id != null){
            state[prefix].Pldo.ContainersToDelete.push(container.Container.id)
        }
        state[prefix].Pldo.Containers.splice(index, 1)
        let i = 0;
        state[prefix].Pldo.Containers.forEach((item) => {
            item.index = i
            i++
        })
    },
    resetCurrentState (state){
        console.log('Resetting this packing list form...')
        state.current.Pldo = getDefaultState().Pldo
        state.current.Pldo.Containers = getDefaultState().Pldo.Containers
        state.current.Pldo.Salesdescriptions = getDefaultState().Pldo.Salesdescriptions
        state.current.Pldo.Shipment = getDefaultState().Pldo.Shipment
        state.newPackingListSaved = false
        state.newPackingListUnsaved = false
    },
    resetState (state){
        state.allActivePackingLists = []
        // state.current.Pldo = getDefaultState().Pldo
        // state.current.Pldo.Containers = getDefaultState().Pldo.Containers
        // state.current.Pldo.Specifications = getDefaultState().Pldo.Specifications
        // state.newPackingListSaved = false
        // state.newPackingListUnsaved = false
    },
    setAllActivePackingLists (state, data) {
        state.allActivePackingLists = data;
    },
    setContainers (state, payload) {
        console.log('Loading shipment containers')
        let prefix = payload.prefix
        let containers = payload.data
        state[prefix].Pldo.Containers = []
        if(containers.length == 0){
            containers.push(getDefaultState().Container)
        }
        containers.forEach((container)=>{
            state[prefix].Pldo.Containers.push(container)
        })
    },
    setCurrentPackingList (state,payload) {
        console.log('Loading current packing list')
        let contract = payload
        state.current.Pldo.Contract.containercount = contract.Contract.containercount
        state.current.Pldo.Contract.id = contract.Contract.id
        state.current.Pldo.Contract.containersize_id = contract.Contract.containersize_id
        state.current.Pldo.Contract.fsc = contract.Contract.fsc
        state.current.Pldo.Contract.partial_no = contract.Contract.partial_no
        state.current.Pldo.Contract.revision_no = contract.Contract.revision_no
        state.current.Pldo.Contract.title = contract.Contract.title
        state.current.Pldo.Contract.version = contract.Contract.version
        state.current.Pldo.Customer.title = contract.Customer.title
        state.current.Pldo.Invoice.id = contract.Invoice.id
        state.current.Pldo.Invoice.invno = contract.Invoice.invno
        state.current.Pldo.Office.title = contract.Office.title
        state.current.Pldo.Supplier.title = contract.Supplier.title
        state.current.Pldo.contract_id = contract.Contract.id
        state.current.Pldo.title = contract.Invoice.invno
    },
    setCurrentSalesdescriptions (state, descriptions) {
        state.current.Salesdescriptions = []
        state.current.Salesdescriptions = descriptions
    },
    setPackingLists (state,data) {
        state.current.PackingLists = data;
    },
    setPackingListToBeUpdated (state, data) {
        state.update.Pldo = data.Pldo
        state.update.Pldo.ContainersToDelete = getDefaultState().Pldo.ContainersToDelete
    },
    setSalesDescriptions (state, payload) {
        console.log('Loading sales descriptions...')
        let prefix = payload.prefix
        let descriptions = payload.data
        state[prefix].Pldo.Salesdescriptions = []
        descriptions.forEach((description)=>{
            state[prefix].Pldo.Salesdescriptions.push(
                {
                    Salesdescription: {
                        id: description.Salesdescription.id,
                        title: description.Salesdescription.title,
                    }
                }
            )
        })
    },
    setShipmentInfo (state, payload) {
        let information = payload.data
        let prefix = payload.prefix
        state[prefix].Pldo.Shipment.etadate = information.Shipment.etadate
        state[prefix].Pldo.Shipment.etddate = information.Shipment.etddate
        if(information.Shipment.shipmentstatus_id == 6){
            state[prefix].Pldo.Shipment.vessel = information.Shipment.shipmentstatus_text
        } else {
            state[prefix].Pldo.Shipment.vessel = null
        }
    },
    setTotalPackingLists (state,val) {
        state.current.totalPackingLists = val
    },
    updateContainers (state, payload){
        let keys = payload['field'].split('.')
        let prefix = payload.prefix
        let index = payload.index
        let value = payload.value
        state[prefix].Pldo.Containers[index][keys[0]][keys[1]] = value
    },
    updateField
}

const actions = {
    cancelPackingList ({commit}) {
        commit('cancelPackingList')
    },
    cancelPackingListById ({commit},val) {
        return new Promise((resolve, reject) => {
            api
                .delete("/packinglists/" + val)
                .then(response => {
                    // if(response.data.status == 'success') {
                    //     resolve('done')
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    convertPackingListById ({commit},packinglist_id){
        return new Promise((resolve, reject) => {
            api
                .get("/packinglists/" + packinglist_id + "/convert-to-appic")
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                    resolve(error)
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    createPackingList ({state, rootState}){
        let packinglist = _.cloneDeep(state.current.Pldo)
        let containers = _.cloneDeep(state.current.Pldo.Containers)
        let containersToDelete = _.cloneDeep(state.current.Pldo.ContainersToDelete)
        let contract = _.cloneDeep(state.current.Pldo.Contract)
        //remove uneeded properties
        delete packinglist.Contract
        delete packinglist.Containers
        delete packinglist.ContainersToDelete
        delete packinglist.Customer
        delete packinglist.Invoice
        delete packinglist.Office
        delete packinglist.Salesdescriptions
        delete packinglist.Shipment
        delete packinglist.Supplier

        return new Promise((resolve, reject) => {
            api
                .post('/packinglists',{
                        contract: contract,
                        containers: containers,
                        containersToDelete: containersToDelete,
                        packinglist: packinglist,
                        packinglistitems: rootState.appic.packinglistitem.current.PackingListItems
                    }
                )
                .then((response)=>{
                    // if(response.data.status == 'success') {
                    //     resolve(response.data)
                    // } else {
                    //     resolve('error')
                    // }
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(()=>{
                    resolve('error')
                })
        })
    },
    getAllActivePackingLists ({commit}) {
        return new Promise((resolve, reject) => {
            api
                .get("/packinglists/active")
                .then(response => {
                    commit('setAllActivePackingLists',response.data.data);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                });
        })
    },
    getAllPackingLists ({commit},payload) {
        // let filterOptions = payload.filterOptions
        // let conditions = []
        return new Promise((resolve, reject) => {
            if(payload.tableOptions.page != null) {
                api
                    .get("/packinglists", {
                        params: {
                            // conditions: conditions,
                            pagination: {
                                skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                                take: payload.tableOptions.itemsPerPage
                            },
                            order: [
                                {
                                    field: 'pldos.pldodate',
                                    direction: 'DESC'
                                },
                            ]
                        }
                    })
                    .then(response => {
                        commit('setPackingLists', response.data.data)
                        commit('setTotalPackingLists', response.data.totalRows)
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            } else {
                resolve('done')
            }
        })
    },
    getContainersByShipment ({commit},payload) {
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        let packinglist_id = payload?.packinglist_id
        let model = 'contracts'
        let id = contract_id
        if(prefix == 'update'){
            id = packinglist_id
            model = 'packinglists'
        }
        return new Promise((resolve, reject) => {
            api
                .get("/"+model+"/" + id + "/containers")
                .then(response => {
                    let payload_containers = {
                        prefix: prefix,
                        data: response.data.data
                    }
                    commit('setContainers',payload_containers);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getPackingListToUpdateById ({commit},packinglist_id) {
        return new Promise((resolve, reject) => {
            api
                .get("/packinglists/" + packinglist_id,{
                })
                .then(response => {
                    commit('setPackingListToBeUpdated',response.data.data[0]);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getSalesdescriptionsByShipment ({commit},payload) {
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/"+contract_id+"/salesdescriptions")
                .then(response => {
                    let payload_salesdescriptions = {
                        prefix: prefix,
                        data: response.data.data
                    }
                    commit('setSalesDescriptions',payload_salesdescriptions);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .finally(()=>{
                    resolve('done')
                })
        })
    },
    getShipmentInfo ({commit},payload) {
        let contract_id = payload.contract_id
        let prefix = payload.prefix
        return new Promise((resolve, reject) => {
            api
                .get("/contracts/" + contract_id + "/shipping",{

                })
                .then(response => {
                    let payload_shipment = {
                        prefix: prefix,
                        data: response.data.data[0]
                    }
                    commit('setShipmentInfo', payload_shipment);
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
    loadUnshippedContract ({commit},payload) {
        return new Promise((resolve, reject) => {
            commit('setCurrentPackingList', payload)
            resolve('done')
        })
    },
    resetAllActivePackingLists ({commit}){
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    resetCurrentPackingList ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetCurrentState')
            resolve('done')
        })
    },
    resetPackingList ({commit}) {
        return new Promise ((resolve, reject) => {
            commit('resetState')
            resolve('done')
        })
    },
    searchAllPackingLists ({commit}, payload) {
        // let conditions = []
        // let obj = {
        //     field: payload.search.field,
        //     value: payload.search.value
        // }
        // conditions.push(obj)
        return new Promise((resolve, reject) => {
            api
                .get("/packinglists",{
                    params: {
                        conditions: payload.conditions,
                        pagination: {
                            skip: (payload.tableOptions.page - 1) * payload.tableOptions.itemsPerPage,
                            take: payload.tableOptions.itemsPerPage
                            // skip: (payload.tableOptions.page - 1) * -1,
                            // take: -1
                        },
                        order: payload.order
                    }
                })
                .then(response => {
                    commit('setPackingLists', response.data.data)
                    commit('setTotalPackingLists', response.data.totalRows)
                    resolve('done')
                })
                .catch(error => {
                    reject(error);
                })
                .then(()=>{
                    resolve('done')
                });
        })
    },
    updatePackingList ({state, rootState}) {
        let packinglist = _.cloneDeep(state.update.Pldo)
        let containers = _.cloneDeep(state.update.Pldo.Containers)
        let containersToDelete = _.cloneDeep(state.update.Pldo.ContainersToDelete)
        let contract = _.cloneDeep(state.update.Pldo.Contract)
        //remove unneeded properties
        delete packinglist.Contract
        delete packinglist.Containers
        delete packinglist.ContainersToDelete
        delete packinglist.Customer
        delete packinglist.Invoice
        delete packinglist.Office
        delete packinglist.Salesdescriptions
        delete packinglist.Shipment
        delete packinglist.Supplier
        delete packinglist.Total

        return new Promise ((resolve, reject) => {
            api
                .put("/packinglists/"+state.update.Pldo.id,{
                    contract: contract,
                    containers: containers,
                    containersToDelete: containersToDelete,
                    packinglist: packinglist,
                    packinglistitems: rootState.appic.packinglistitem.update.PackingListItems
                })
                .then(response => {
                    this.updatedPackingListSaved = true
                    this.updatedPackingListUnsaved = false
                    // resolve('done')
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                    // resolve(error)
                })
                .then(()=>{
                    resolve('done')
                });
        })
    }
}

export const packinglist = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}